import allImgPaths from 'assets/allImgPaths';
import { useApiAccess } from 'contexts/AccessProvider';
import { useShardDetailsQuery } from 'gql';
import { useParams } from 'react-router-dom';

import ShardData from './components/ShardData';
const useShardDetails = () => {
  const { id } = useParams();
  const { sessionKey } = useApiAccess();
  const {
    data,
    loading: isLoading,
    error,
  } = useShardDetailsQuery({
    variables: {
      shardId: id as string,
      sessionKey,
    },
  });

  const shardData = ShardData({ data });

  const shardMembers =
    data?.shardDetails?.members.map((member) => ({
      image: allImgPaths.profileIcon,
      title: 'Chronicle',
      text: member.account_id,
    })) || [];
  return { isLoading, shardMembers, shardData, id, data, error };
};
export default useShardDetails;
