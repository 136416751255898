import React, { ReactNode } from 'react';

const renderRows = (obj: any) => {
  return Object.entries(obj).map(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      return (
        <React.Fragment key={key}>
          <tr className="border border-solid border-[#1F1F1F] border-r-0 w-full">
            <td className="p-2 text-[#A6A3AC] font-bold">{key}</td>
            <td colSpan={2}>
              <table>
                <tbody>{renderRows(value)}</tbody>
              </table>
            </td>
          </tr>
        </React.Fragment>
      );
    } else {
      return (
        <tr key={key} className="">
          <td className="p-2 text-[#A6A3AC] border border-solid border-[#1F1F1F] whitespace-nowrap">
            {key}
          </td>
          <td className="p-2 text-[#D163E2] border border-solid border-[#1F1F1F] w-full">
            {(value as ReactNode) || 'null'}
          </td>
        </tr>
      );
    }
  });
};

const VerticalTable = ({ jsonData }: { jsonData: any }) => {
  return (
    <table border={1} cellSpacing={10} className="w-full">
      <tbody>{renderRows(jsonData)}</tbody>
    </table>
  );
};

VerticalTable.displayName = 'VerticalTable';
export default VerticalTable;
