import { useApiAccess } from 'contexts/AccessProvider';
import { useValidatorDetailsQuery } from 'gql';
import { useParams } from 'react-router-dom';

import ValidatorData from './components/ValidatorData';

const useValidatorDetails = () => {
  const { sessionKey } = useApiAccess();

  const { id } = useParams();

  const { data: validator, loading: isLoading } = useValidatorDetailsQuery({
    variables: { address: id as string, sessionKey },
    skip: !id,
  });

  const validatorData = ValidatorData({ validator: validator });

  return { isLoading, validator, validatorData, error: !validator?.validatorDetails && !isLoading };
};

export default useValidatorDetails;
