import { useMemo, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useListTasksQuery } from 'gql';
import { useFilter, usePagination, useSortBy, useWindowSize } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { GMPTaskType } from 'types';

import { taskColumn } from './components/TaskColumns';

function useGMPList() {
  const { sessionKey } = useApiAccess();
  const navigate = useNavigate();
  const [phase, setPhase] = useState('');

  const windowSize = useWindowSize();

  const {
    PageController: taskPageController,
    setTotalDataCount: setTotalTaskDataCount,
    pageSize: taskPageSize,
    pageNumber: taskPageNumber,
    setPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { FilterController, selectedField } = useFilter(FilterType.Tasks, setPageNumber);

  const { sortField, sortOrder, onSort } = useSortBy();

  const {
    data: taskData,
    loading: tasksLoading,
    previousData,
  } = useListTasksQuery({
    variables: {
      sessionKey,
      page: { size: taskPageSize, number: taskPageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
      phase,
      taskType: GMPTaskType.SendMessage,
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalTaskDataCount(data.listTasks.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { tasksData, taskColumns } = useMemo(() => {
    const array = taskColumn({ navigate, setPhase });
    if (windowSize.width < 769 && windowSize.width !== 0) {
      const element = array.splice(6, 1)[0];
      array.splice(3, 0, element);
    }
    return {
      tasksData: taskData ? taskData : previousData,
      taskColumns: array,
    };
  }, [navigate, taskData]);

  const [pageSize, PageController] = useMemo(
    () => [taskPageSize, taskPageController],
    [taskPageSize, taskPageController]
  );

  return {
    tasksDataCount: taskData ? taskData?.listTasks.count : previousData?.listTasks.data,
    tasksLoading,
    sortOrder,
    onSort,
    taskColumns,
    tasksData,
    pageSize,
    PageController,
    FilterController,
  };
}

export default useGMPList;
