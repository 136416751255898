import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { TruncatedText } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { NavigateFunction } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { extractFirstValue, getIcon, parseJson } from 'utils';

interface ShardColumnProps {
  navigate: NavigateFunction;
}
const ShardColumn = ({ navigate }: ShardColumnProps) => [
  {
    title: 'Shard ID',
    key: 'id',
    shouldWrap: false,
    render: (data: ColumnData) => (
      <div className="flex gap-[10px] col-span-2 md:col-span-1">
        <Icon icon={allImgPaths.hashTagOutlineIcon} iconName="shard" />
        <div className={'flex flex-col justify-center overflow-hidden w-full cursor-pointer'}>
          <TableColumns
            value={data[data.columnKey]}
            classNames={{ content: 'cursor-pointer' }}
            textColor={FontColors.PURPLE}
            onClick={() => {
              data[data.columnKey].toString() &&
                navigate(`${routes.shard}/${data[data.columnKey].toString()}`);
            }}
          />
        </div>
      </div>
    ),
  },
  {
    title: 'Network',
    key: 'network',
    render: (data: ColumnData) => {
      const parsedData = parseJson(data[data.columnKey]);
      return (
        <Skeleton.Loader className="h-4 w-10" containerClassName="flex">
          <div className="flex gap-1 flex-wrap">
            {parsedData && parsedData.length
              ? parsedData?.map((value: string, index: number) => (
                  <TableColumns
                    key={index}
                    beforeImagePath={value && getIcon(value)}
                    value={value + (index < parsedData.length - 1 ? ',' : '')}
                    textColor={FontColors.WHITE}
                    classNames={{ container: '!w-fit', base: '!w-fit', content: 'capitalize' }}
                  />
                ))
              : '-'}
          </div>
        </Skeleton.Loader>
      );
    },
  },
  {
    title: 'State',
    key: 'state',
    render: (data: ColumnData) => (
      <TableColumns
        value={data[data.columnKey]}
        beforeImagePath={data[data.columnKey] ? getIcon(data[data.columnKey]) : '-'}
        classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
      />
    ),
  },
  {
    title: 'Commitment',
    key: 'commitment',
    tooltipContent:
      'A TSS primitive that the FROST protocol uses to prove that input and output amounts match for Chronicle Nodes participating in the signing process.',
    render: (data: ColumnData) => (
      <Skeleton.Loader className="h-6 w-20 !rounded-full" containerClassName="h-6">
        <TruncatedText
          withCopyButton
          originalText={extractFirstValue(data[data.columnKey])}
          colIndex="_commitment"
        />
      </Skeleton.Loader>
    ),
  },
  {
    title: 'Signature',
    key: 'sig',
    tooltipContent:
      'A cryptographic output derived from the combined private key shares of all the active nodes in a designated shard that attests to the validity of a given cross-chain request .',
    render: (data: ColumnData) => {
      return (
        <Skeleton.Loader className="h-6 w-20 !rounded-full" containerClassName="h-6">
          {data ? (
            <TruncatedText
              withCopyButton
              originalText={extractFirstValue(data[data.columnKey])}
              width="80%"
              colIndex="_signature"
            />
          ) : (
            '-'
          )}
        </Skeleton.Loader>
      );
    },
  },
];

export default ShardColumn;
