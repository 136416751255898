import { useState } from 'react';

import { Tooltip } from '@analog/ui/src';
import { SortArrowDownIcon } from 'components/Icons/SortArrowDownIcon';
import { SortArrowUpIcon } from 'components/Icons/SortArrowUpIcon';
import { ColumnsType } from 'types';
import { classNames, classes } from 'utils';

type TableHeaderProps = {
  columns: ColumnsType[];
  hasHeader: boolean;
  classNames?: classNames<'row' | 'columns'>;
  sortOptions?: { [key: string]: string | undefined }[] | undefined;
  sortOrder?: string;
  onSort?: (field?: string) => void;
  data: any;
};

const TableHeader = ({
  columns,
  hasHeader,
  classNames,
  sortOptions,
  sortOrder,
  onSort,
  data,
}: TableHeaderProps) => {
  const [sortedField, setSortedField] = useState('');
  return (
    <>
      <div className="hidden md:block text-offWhite pb-3 border-solid border-t-0 border-l-0 border-r-0 border-[1px] border-lightGray">
        <div className={classNames?.row}>
          {columns.map(
            (
              {
                title,
                renderHeader,
                tooltipContent,
                key,
              }: {
                title: string;
                key: string;
                tooltipContent?: string;
                renderHeader?: ({ data }: { data: any }, key: string) => JSX.Element;
              },
              index: number
            ) => {
              const option = sortOptions?.find((el) => Object.keys(el)[0] == title);
              return (
                <div key={index} className="text-xs text-ellipsis whitespace-nowrap">
                  <div
                    className={classes(
                      'flex items-center gap-[2px] cursor-pointer',
                      columns.length - 1 === index && 'justify-end',
                      classNames?.columns
                    )}
                    onClick={() => {
                      setSortedField(option?.[title] || '');
                      if (option?.[title] && onSort) {
                        onSort(option[title]);
                      }
                    }}
                  >
                    {!renderHeader && (
                      <span
                        className="leading-5 capitalize"
                        data-tooltip-id={title}
                        data-tooltip-html={tooltipContent}
                      >
                        {title}
                      </span>
                    )}
                    {renderHeader && renderHeader({ data }, key)}
                    {option && (
                      <div className="flex flex-col ml-0.5 gap-[1px]">
                        <button className="h-1.5">
                          <SortArrowUpIcon
                            className={`w-[7px] h-[7px] ${
                              sortedField === option?.[title] && sortOrder === 'asc'
                                ? 'text-pink'
                                : 'text-offWhite'
                            }`}
                          />
                        </button>
                        <button className="h-1.5">
                          <SortArrowDownIcon
                            className={`w-[7px] h-[7px] ${
                              sortedField === option?.[title] && sortOrder === 'desc'
                                ? 'text-pink'
                                : 'text-offWhite'
                            }`}
                          />
                        </button>
                      </div>
                    )}
                    {tooltipContent && <Tooltip id={title} delayShow={500} />}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {hasHeader && (
        <div className="md:hidden border-solid border-t-0 border-l-0 border-r-0 border-[1px] border-lightGray mb-4" />
      )}
    </>
  );
};

export default TableHeader;
