import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, SortOrderType, useListBlocksQuery } from 'gql';
import { useFilter, usePagination, useSortBy } from 'hooks';

import BlockColumn from './components/BlockColumns';

function useBlockList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { FilterController, selectedField } = useFilter(FilterType.Block, setPageNumber);

  const { sortField, sortOrder, onSort } = useSortBy();

  const modifiedSortOrder =
    sortField === 'created_at' && sortOrder === SortOrderType.Asc
      ? SortOrderType.Desc
      : sortField === 'created_at' && sortOrder === SortOrderType.Desc
      ? SortOrderType.Asc
      : sortOrder;

  const {
    data: blockData,
    loading: isLoading,
    previousData,
  } = useListBlocksQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: modifiedSortOrder },
      }),
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listBlocks.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const COLUMNS = BlockColumn();

  return {
    COLUMNS,
    PageController,
    blockData: blockData ? blockData?.listBlocks.data : previousData?.listBlocks.data,
    isLoading,
    pageSize,
    sortOrder,
    onSort,
    FilterController,
  };
}

export default useBlockList;
