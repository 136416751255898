import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, SortOrderType, useListExtrinsicsQuery } from 'gql';
import { useFilter, usePagination, useSortBy } from 'hooks';

import ExtrinsicsColumn from './components/ExtrinsicsColumn';

export function useExtrinsics() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber, setPageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });
  const { FilterController, selectedField } = useFilter(FilterType.Extrinsics, setPageNumber);

  const { sortField, sortOrder, onSort } = useSortBy();

  const modifiedSortOrder =
    sortField === 'created_at' && sortOrder === SortOrderType.Asc
      ? SortOrderType.Desc
      : sortField === 'created_at' && sortOrder === SortOrderType.Desc
      ? SortOrderType.Asc
      : sortOrder;

  const {
    data: extrinsicData,
    loading: isLoading,
    previousData,
  } = useListExtrinsicsQuery({
    variables: {
      sessionKey,
      page: { size: pageSize, number: pageNumber },
      ...(sortField && {
        sort: { field: sortField, order: modifiedSortOrder },
      }),
      filter: selectedField,
    },
    skip: selectedField.column ? !selectedField.value : false,
    onCompleted(data) {
      setTotalDataCount(data.listExtrinsics.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const COLUMNS = ExtrinsicsColumn();
  return {
    PageController,
    extrinsicsData: extrinsicData
      ? extrinsicData?.listExtrinsics.data
      : previousData?.listExtrinsics.data,
    isLoading,
    pageSize,
    pageNumber,
    sortOrder,
    onSort,
    COLUMNS,
    FilterController,
  };
}
