import { TasksTable } from 'components/common/TasksTable';

import useTaskTable from './useTaskTable';

export function TaskTable() {
  const { taskListData, tasksSortOrder, tasksOnSort, tasksLoading, setPhase } = useTaskTable();
  return (
    <TasksTable
      animation
      taskData={[...(taskListData || [])]}
      classNames={{
        tableBase: 'md:max-h-[530px] overflow-auto',
        bodyRow: '!py-4 items-start !h-auto grid grid-cols-2',
        row: 'last:border-none',
        bodyWrapper:
          'flex flex-col min-h-auto h-auto min-h-[200px] max-h-[360px] overflow-auto border-b border-lightGray',
      }}
      sortOrder={tasksSortOrder}
      onSort={tasksOnSort}
      isLoading={tasksLoading}
      onPhaseChange={setPhase}
      alwaysShowHeader
    />
  );
}
