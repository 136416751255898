import { Tooltip } from '@analog/ui/src';
import { Card, Heading, SearchBox, Table } from 'components';
import { Skeleton } from 'components/Skeleton';
import { SortColumnsMembers, arrayOfSize } from 'utils';

import ChronicleColumns from './components/ChronicleColumns';
import useChronicleList from './useChronicleList';

export const membersColumn = ChronicleColumns();

const ChronicleList = () => {
  const { PageController, membersData, isLoading, pageSize, sortOrder, onSort, FilterController } =
    useChronicleList();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          title="Chronicles"
          subTitle="Explore details about member information, including account id, network, signature, etc."
        />
        <div className="flex gap-2">
          <SearchBox defaultSearchType={'Chronicles'} />
          {FilterController}
        </div>
      </div>
      <div className="mt-8 md:mt-[60px]">
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col">
          <span className="text-white text-xl">Chronicles</span>
          <Table
            columns={membersColumn}
            hasHeader
            data={!isLoading ? membersData : arrayOfSize(pageSize)}
            classNames={{
              row: 'grid gap-x-2 grid-cols-2 md:grid-cols-[minmax(140px,35%)_minmax(110px,15%)_minmax(130px,30%)_minmax(100px,10%)_minmax(100px,7%)_minmax(100px,5%)]',
            }}
            sortOptions={SortColumnsMembers}
            sortOrder={sortOrder}
            onSort={onSort}
            isLoading={isLoading}
          />
          <Skeleton.Provider isLoading={isLoading}>
            {(isLoading || membersData?.length) && PageController}
          </Skeleton.Provider>
        </Card>
      </div>
      <Tooltip id={'signature'} />
    </>
  );
};

export default ChronicleList;
