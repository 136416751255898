import { DetailsList } from '@analog/ui';
import { Heading, ResourceNotFound, SearchBox } from 'components';

import useChroniclesDetails from './useChroniclesDetails';

export function ChronicleDetails() {
  const { chronicleData, isLoading, id, error } = useChroniclesDetails();

  if (error) {
    return <ResourceNotFound page={'chronicles'} searchType={'Chronicles'} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          className="text-white font-medium leading-none truncate w-full"
          title={`Chronicle`}
          subTitle={`#${id}`}
          isLoading={isLoading}
          isTruncate
        />
        <SearchBox defaultSearchType={'Chronicles'} />
      </div>
      <div className="md:mt-[40px] mt-[32px]">
        <DetailsList title={'Chronicle Info'} details={chronicleData} isLoading={isLoading} />
      </div>
    </>
  );
}

export default ChronicleDetails;
