import { HTMLAttributes, ReactNode } from 'react';

import { Skeleton } from 'components/Skeleton';
import { useWindowSize } from 'hooks';
import { classNames, classes, truncate } from 'utils';

interface HeadingProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle?: string | ReactNode;
  placeHolder?: string;
  classNames?: classNames<'title' | 'subtitle'>;
  isLoading?: boolean;
  isTruncate?: boolean;
}

const Heading = ({
  title,
  subTitle,
  classNames,
  className,
  isLoading,
  isTruncate = false,
}: HeadingProps) => {
  const { isMobile } = useWindowSize();
  const subtitleText = isTruncate && isMobile ? truncate(subTitle as string, 12) : subTitle;
  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className={classes('flex flex-col gap-2', className, classNames?.base)}>
        <Skeleton.Loader
          className="w-full max-w-[243px] sm:h-[56px] h-10"
          containerClassName=" h-10 sm:h-[56px]"
        >
          <span
            className={classes(
              'text-white text-[32px] sm:text-[40px] md:text-[56px] font-medium md:leading-[72px] leading-[40px] capitalize',

              classNames?.title
            )}
          >
            {title}
          </span>
        </Skeleton.Loader>
        {subTitle && (
          <Skeleton.Loader
            className="sm:h-6 h-[41px] w-full max-w-[627px]"
            containerClassName="sm:h-6 h-[41px]"
          >
            <span
              className={classes(
                'text-offWhite text-sm md:text-base break-words',
                classNames?.subtitle
              )}
            >
              {subtitleText}
            </span>
          </Skeleton.Loader>
        )}
      </div>
    </Skeleton.Provider>
  );
};

export default Heading;
