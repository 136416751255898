import { createContext, useContext, useState } from 'react';
import { default as RLS, SkeletonProps as RLSProps } from 'react-loading-skeleton';
import cn from '../../utils/classnames';

export type SkeletonProps = React.HTMLAttributes<HTMLDivElement> &
  RLSProps & {
    isLoading?: boolean;
  };

const SkeletonContext = createContext<boolean | undefined>(false);

function Provider({ children, isLoading }: React.PropsWithChildren<{ isLoading?: boolean }>) {
  const [testMode, setTestMode] = useState(false);
  return (
    <SkeletonContext.Provider value={isLoading || testMode}>
      {children}
      {(window as any).showLoadingToggle && (
        <div className="fixed bottom-7 right-2 text-white">
          <button className="z-50" onClick={() => setTestMode((prev) => !prev)}>
            Toggle Loading
          </button>
        </div>
      )}
    </SkeletonContext.Provider>
  );
}

function Loader({ children, className, containerClassName, ...props }: SkeletonProps) {
  const isLoading = useSkeleton();

  if (isLoading || props.isLoading) {
    return (
      <RLS
        containerClassName={cn('block leading-none', containerClassName)}
        className={cn('block bg-[#FFFFFF1F] !rounded-[32px]', className)}
        {...props}
      />
    );
  }

  return <>{children}</>;
}

export const Skeleton = {
  Loader,
  Provider,
};

export const useSkeleton = () => useContext(SkeletonContext);
