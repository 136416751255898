import { CopyToClipboard } from '@analog/ui/src';
import { Text, TruncatedText } from 'components';
import { Skeleton } from 'components/Skeleton';
import { HTMLAttributes } from 'types';
import { classNames, classes } from 'utils';

export interface ChipProps extends HTMLAttributes<HTMLDivElement> {
  classNames?: classNames<
    | 'content'
    | 'container'
    | 'beforeIcon'
    | 'skeletonIcon'
    | 'skeletonLabel'
    | 'skeletonText'
    | 'copyIcon'
  >;
  value: string;
  icon?: React.ReactNode;
  withCopyButton?: boolean;
  colIndex?: string;
  dataTooltipContent?: string;
  dataTooltipId?: string;
  textColor?: string;
  beforeImagePath?: string;
  label?: string;
  onClick?: () => void;
  copyValue?: string;
  withTruncate?: boolean;
}

function Chip({
  value,
  icon,
  classNames,
  colIndex,
  dataTooltipContent,
  dataTooltipId,
  textColor,
  withCopyButton = false,
  onClick,
  copyValue,
  withTruncate = false,
}: ChipProps) {
  return (
    <Skeleton.Loader>
      <div className={classes('flex items-center rounded-3xl w-auto pr-2', classNames?.container)}>
        {icon && (
          <div
            className={classes(
              'size-6 flex-shrink-0 rounded-full bg-[#232222] flex items-center justify-center p-1.5',
              classNames?.beforeIcon
            )}
          >
            {icon}
          </div>
        )}
        <Skeleton.Loader
          className={classes(
            'h-4 w-24 !rounded-full',
            withCopyButton && 'w-full',
            classNames?.skeletonText
          )}
          containerClassName={classes('h-4 flex', withCopyButton && 'w-full')}
        >
          {value && withTruncate ? (
            <TruncatedText
              data-tooltip-id={dataTooltipId}
              colIndex={colIndex}
              data-tooltip-content={dataTooltipContent}
              originalText={value}
              textColor={textColor}
              withCopyButton={withCopyButton}
              className={classes(icon ? 'pl-1.5' : 'pl-2', classNames?.content)}
              onClick={() => onClick && onClick()}
            />
          ) : (
            <Text
              data-tooltip-id={dataTooltipId}
              data-tooltip-content={dataTooltipContent}
              color={textColor}
              extraClass={classes(icon ? 'pl-1.5' : 'pl-2', 'truncate', classNames?.content)}
              onClick={() => onClick && onClick()}
            >
              {value}
            </Text>
          )}
        </Skeleton.Loader>
        {withCopyButton && !withTruncate && value && value != '-' && (
          <Skeleton.Loader className="h-4 w-4 !rounded-full" containerClassName="h-4">
            <CopyToClipboard icon content={copyValue || value} id={value + colIndex} />
          </Skeleton.Loader>
        )}
      </div>
    </Skeleton.Loader>
  );
}

export default Chip;
