import { DetailsList } from '@analog/ui/src';
import { Card, ResourceNotFound, SearchBox, Table } from 'components';
import Heading from 'components/common/Heading';
import { Skeleton } from 'components/Skeleton';
import { arrayOfSize, SortColumnsExtrinsics } from 'utils';

import useAccountDetails from './useAccountDetails';

function AccountDetails() {
  const {
    id,
    accountData,
    extrinsicData,
    isExtrinsicsLoading,
    EXTRINSICS_COLUMNS,
    pageSize,
    PageController,
    onSort,
    sortOrder,
    isAccountLoading,
    error,
  } = useAccountDetails();

  if (error) {
    return <ResourceNotFound page={'accounts'} searchType={'Accounts'} />;
  }

  return (
    <Skeleton.Provider isLoading={isAccountLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          className="text-white font-medium leading-none truncate w-full"
          title={`Account`}
          subTitle={`#${id}`}
          isTruncate
        />
        <SearchBox defaultSearchType={'Accounts'} />
      </div>
      <div className="md:mt-[40px] mt-[32px] ">
        <DetailsList title="Account Info" details={accountData} isLoading={isAccountLoading} />
        <Card extraClasses="flex gap-4 md:gap-[22px] flex-col mt-[32px]">
          <span className="text-white text-xl">
            <div>
              Extrinsics
              <span className="text-[#A6A3AC]"> {extrinsicData?.count || 0}</span>
            </div>
          </span>

          <Table
            columns={EXTRINSICS_COLUMNS}
            classNames={{
              row: 'grid gap-x-3 grid-cols-2 md:grid-cols-[minmax(90px,20%)_minmax(120px,25%)_minmax(130px,15%)_minmax(130px,15%)_minmax(130px,25%)] border-none',
              columns: 'justify-start',
            }}
            data={!isExtrinsicsLoading ? extrinsicData?.data : arrayOfSize(pageSize)}
            sortOptions={SortColumnsExtrinsics}
            sortOrder={sortOrder}
            onSort={onSort}
            isLoading={isExtrinsicsLoading}
          />
          {extrinsicData?.count && PageController}
        </Card>
      </div>
    </Skeleton.Provider>
  );
}

export default AccountDetails;
