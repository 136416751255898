import { Skeleton } from 'components/Skeleton';
// eslint-disable-next-line import/order
import NoResult from 'components/common/NoResult';
import { ColumnsType } from 'types';
import { classNames, classes } from 'utils';

import TableBody from './components/TableBody';
import TableHeader from './components/TableHeader';
import TableWrapper from './components/TableWrapper';

type TableProps = {
  columns: ColumnsType[];
  classNames?: classNames<'row' | 'columns' | 'bodyRow' | 'bodyWrapper' | 'bodyBase'>;
  data: any;
  hasHeader?: boolean;
  isHeaderHidden?: boolean;
  isAccordion?: boolean;
  animation?: boolean;
  sortOptions?: { [key: string]: string | undefined }[] | undefined;
  sortOrder?: string;
  onSort?: (field?: string) => void;
  bodyId?: string;
  isLoading?: boolean;
  alwaysShowHeader?: boolean;
  uniqueKey?: string;
};

const Table = ({
  columns,
  classNames,
  data,
  isAccordion = false,
  hasHeader = false,
  isHeaderHidden = false,
  sortOptions,
  sortOrder,
  onSort,
  bodyId,
  isLoading = false,
  alwaysShowHeader = false,
  uniqueKey,
}: TableProps) => {
  return (
    <TableWrapper classNames={{ base: classNames?.base }}>
      {(alwaysShowHeader || (!isHeaderHidden && !!data?.length)) && (
        <TableHeader
          columns={columns}
          classNames={classNames}
          hasHeader={hasHeader}
          sortOptions={sortOptions}
          sortOrder={sortOrder}
          onSort={onSort}
          data={data}
        />
      )}

      <Skeleton.Provider isLoading={isLoading}>
        {data?.length ? (
          <TableBody
            classNames={{
              row: classes(classNames?.row, classNames?.bodyRow),
              rowWrapper: classNames?.bodyWrapper,
              base: classNames?.bodyBase,
            }}
            columns={columns}
            data={data}
            isHeaderHidden={isHeaderHidden}
            isAccordion={isAccordion}
            id={bodyId}
            uniqueKey={uniqueKey}
          />
        ) : (
          <div className={`${classNames?.bodyWrapper} flex justify-center items-center`}>
            <NoResult />
          </div>
        )}
      </Skeleton.Provider>
    </TableWrapper>
  );
};

export default Table;
