import { Icon, Tooltip } from '@analog/ui/src';
import { Skeleton } from 'components/Skeleton';
import { classes } from 'utils';

type TitleCardType = {
  icon: string;
  title: string;
  titleClasses?: string;
  iconClass?: string;
  tooltipContent?: string;
};

const TitleIconCard = ({
  icon,
  title,
  titleClasses = '',
  iconClass = '',
  tooltipContent,
}: TitleCardType) => {
  return (
    <>
      <div className="flex gap-[10px] items-center">
        <Skeleton.Loader className="h-10 w-10 !rounded-xl" containerClassName="h-10">
          <Icon icon={icon} iconName={title} iconClasses={classes('w-5 h-5', iconClass)} />
        </Skeleton.Loader>
        <Skeleton.Loader className="w-[90px] h-4" containerClassName="h-4">
          <span
            className={classes('text-white text-sm', titleClasses)}
            data-tooltip-id={title}
            data-tooltip-html={tooltipContent}
          >
            {title}
          </span>
        </Skeleton.Loader>
        {tooltipContent && <Tooltip id={title} delayShow={500} place="top-start" />}
      </div>
    </>
  );
};

export default TitleIconCard;
