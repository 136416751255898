import ReactJson from '@uiw/react-json-view';
import { useState } from 'react';
import { Button } from '../Button/Button';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';
import VerticalTable from './VerticalTable';

export type JsonTableProps = {
  index: string; // Need to use key, Currently not implemented because getting render issue.
  jsonData: any;
};

export function JsonTable({ index, jsonData }: JsonTableProps) {
  const [isTable, setIsTable] = useState(true);

  return (
    <>
      <div className="max-h-[350px] p-4 bg-[#111010] overflow-y-auto">
        {isTable ? (
          <div className="">{jsonData && <VerticalTable jsonData={jsonData} />}</div>
        ) : (
          <ReactJson value={jsonData} enableClipboard={false} displayDataTypes={true} />
        )}
      </div>
      <div className="bg-[#111010] flex py-2 justify-end pr-3 gap-2  border-solid border-[#1f1f1f] border-0">
        <CopyToClipboard id={index} content={JSON.stringify(jsonData)}>
          <Button variant="default">Copy</Button>
        </CopyToClipboard>

        <Button onClick={() => setIsTable(!isTable)} variant="default">
          {isTable ? 'View Code' : 'Decode'}
        </Button>
      </div>
    </>
  );
}

JsonTable.displayName = 'JsonTable';
