import { useApiAccess } from 'contexts/AccessProvider';
import { useMemberDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';

import ChronicleData from './components/ChronicleData';

const useChroniclesDetails = () => {
  const { id } = useParams();

  const { sessionKey } = useApiAccess();

  const navigate = useNavigate();

  const {
    data: chroniclesData,
    loading: isLoading,
    error,
  } = useMemberDetailsQuery({
    variables: {
      accountId: id || '',
      sessionKey,
    },
  });

  const chronicleData = ChronicleData({ chroniclesData });

  return { chronicleData, isLoading, id, error };
};
export default useChroniclesDetails;
