import { FC } from 'react';
import cn from '../../utils/classnames';
import { getIcon } from '../../utils/getIcon';
import { parseJson } from '../../utils/parseJson';

export type NetworkDetailsTypes = {
  data: any;
  className?: string;
};

export const NetworkDetails: FC<NetworkDetailsTypes> = ({ data, className }) => {
  let parseData = parseJson(data);
  if (!Array.isArray(parseData)) {
    parseData = [];
  }
  return parseData.map((value: string, index: number) => {
    return (
      <div
        key={index}
        className={cn(
          'text-stone-50 text-sm font-normal leading-snug flex items-center gap-2',
          className
        )}
      >
        {getIcon(value) && <img src={getIcon(value)} className="w-6 h-6" />}
        <span key={index} className="capitalize">
          {value + (index < parseJson(data).length - 1 ? ',' : '')}
        </span>
      </div>
    );
  });
};

NetworkDetails.displayName = 'NetworkDetails';
