import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';

interface EventColumn {
  isMobile: boolean;
}

const EventColumn = ({ isMobile }: EventColumn) => {
  const navigate = useNavigate();
  return [
    {
      title: 'Event Id',
      key: 'eventId',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Icon
            icon={isMobile ? allImgPaths.dataArrowsIcon : allImgPaths.calendarTickIcon}
            iconName="profile"
          />
          <div className={'flex flex-col overflow-hidden w-full'}>
            <TableColumns
              value={data[data.columnKey]}
              classNames={{ content: 'cursor-pointer w-fit text-white' }}
              label={isMobile ? 'Events' : 'Event'}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Extrinsic Id',
      key: 'extrinsicId',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] || '-'}
          textColor={FontColors.PURPLE}
          classNames={{ content: 'cursor-pointer' }}
          onClick={() => {
            data && navigate(`${routes.extrinsic}/${data[data.columnKey]}`);
          }}
        />
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (data: ColumnData) => (
        <Text extraClass="text-white">{data[data.columnKey] || '-'}</Text>
      ),
    },
  ];
};

export default EventColumn;
