import { useMemo, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from 'consts';
import { useApiAccess } from 'contexts/AccessProvider';
import { useListTasksQuery } from 'gql';
import { usePagination, useSortBy, useWindowSize } from 'hooks';
import { taskColumn } from 'pages/GMP/GMPList/components/TaskColumns';
import { useNavigate, useParams } from 'react-router-dom';
import { GMPTaskType } from 'types';

export const useTaskData = () => {
  const { sessionKey } = useApiAccess();
  const navigate = useNavigate();
  const { id } = useParams();
  const [phase, setPhase] = useState('');

  const windowSize = useWindowSize();

  const {
    PageController: taskPageController,
    setTotalDataCount: setTotalTaskDataCount,
    pageSize: taskPageSize,
    pageNumber: taskPageNumber,
  } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: taskData, loading: tasksLoading } = useListTasksQuery({
    variables: {
      sessionKey,
      page: { size: taskPageSize, number: taskPageNumber },
      ...(sortField && {
        sort: { field: sortField, order: sortOrder },
      }),
      phase,
      shardId: id,
      taskType: GMPTaskType.SendMessage,
    },
    onCompleted(data) {
      setTotalTaskDataCount(data.listTasks.count);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { tasksData, taskColumns } = useMemo(() => {
    const array = taskColumn({ navigate, setPhase });
    if (windowSize.width < 769 && windowSize.width !== 0) {
      const element = array.splice(6, 1)[0];
      array.splice(3, 0, element);
    }
    return {
      tasksData: taskData,
      taskColumns: array,
    };
  }, [navigate, taskData]);

  const [pageSize, PageController] = useMemo(
    () => [taskPageSize, taskPageController],
    [taskPageSize, taskPageController]
  );

  return {
    tasksDataCount: taskData?.listTasks.count,
    tasksLoading,
    sortOrder,
    onSort,
    taskColumns,
    tasksData,
    pageSize,
    PageController,
  };
};
