import { useState } from 'react';

import allImgPaths from 'assets/allImgPaths';
import { ExplorerLogo } from 'components/svg';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes, pathMatcher } from 'utils';

import { NavBarOptions } from './consts';

const ActiveTabClasses =
  "text-white relative after:content-[''] after:bg-white after:h-[2px] after:rounded-tl after:rounded-tr after:w-full after:absolute after:left-0 after:bottom-[-26px] after:text-red-400";

interface MobileDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

const NavBar = () => {
  const { pathname } = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    const ele = document.getElementsByTagName('body')[0] || null;
    if (ele) {
      if (isDrawerOpen) {
        ele.style.overflow = '';
        ele.style.height = '';
      } else {
        ele.style.overflow = 'hidden';
        ele.style.height = '100vh';
      }
    }
    setIsDrawerOpen(!isDrawerOpen);
  };

  const MobileDrawer = ({ isOpen, toggleDrawer }: MobileDrawerProps) => {
    return (
      <div
        className={classes('h-screen relative bg-[#010101]', !isOpen && 'hidden')}
        id="mobile-menu"
      >
        <div className="flex flex-col w-full gap-[15px] pt-[14px]">
          {NavBarOptions.map(({ title, path }, index) => (
            <NavLink to={path} key={index} onClick={toggleDrawer}>
              <span className="text-white tracking-[0.48px] text-2xl cursor-pointer">{title}</span>
              <div className="border-[1px] border-t-0 mt-4 border-l-0 border-r-0 border-solid border-lightGray" />
            </NavLink>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="sticky top-0 z-40 px-4 md:px-[50px] 2xl:px-[120px] pt-[13px] pb-[11px] bg-[#010101] shadow-[0px_4px_29.8px_0px_#ffffff0d]">
      <nav className="flex flex-col gap-2 md:gap-0">
        <div className="flex items-center justify-between md:p-0">
          <div className="flex items-center justify-between flex-1 gap-2">
            <Link to={routes.base} className="cursor-pointer">
              <ExplorerLogo />
            </Link>
            <div className="hidden lg:block">
              <div className="flex gap-2 lg:gap-4 xl:gap-10">
                {NavBarOptions.map(({ title, path }, index) => (
                  <NavLink to={path} key={index}>
                    <span
                      className={classes(
                        'text-[#C8C8C8] tracking-[0.48px] cursor-pointer hover:text-white transition-[color]',
                        pathMatcher(pathname, path) && ActiveTabClasses
                      )}
                    >
                      {title}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>

          <div className="flex lg:hidden">
            <button
              type="button"
              className="relative inline-flex items-center justify-center rounded-md text-white"
              onClick={handleDrawerToggle}
            >
              <img
                src={isDrawerOpen ? allImgPaths.closeIcon : allImgPaths.menuIcon}
                className="h-6 w-6"
              />
            </button>
          </div>
        </div>
        <MobileDrawer isOpen={isDrawerOpen} toggleDrawer={handleDrawerToggle} />
      </nav>
    </div>
  );
};

export default NavBar;
