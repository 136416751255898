import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { classes, statusColor, timeAgo } from 'utils';

const BlockColumn = () => {
  const navigate = useNavigate();
  return [
    {
      title: 'Block',
      key: 'number',
      shouldWrap: false,
      render: (data: ColumnData) => (
        <div className="flex gap-[10px] col-span-2 md:col-span-1">
          <Icon icon={allImgPaths.layerIcon} iconName="profile" extraClasses="w-10 min-w-10" />
          <div className={'flex flex-col justify-center overflow-hidden w-full'}>
            <TableColumns
              value={`#${data[data.columnKey]}`}
              classNames={{ content: 'cursor-pointer w-fit' }}
              textColor={FontColors.PURPLE}
              label="Block ID"
              onClick={() => {
                data && navigate(`${routes.block}/${data[data?.columnKey]}`);
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (data: ColumnData) => (
        <Skeleton.Loader className="!rounded-full h-4 w-[75px]" containerClassName="h-4">
          <div
            className={classes(
              `rounded-3xl py-[2px] px-[7px] inline-flex text-ellipsis overflow-hidden whitespace-nowrap w-fit`,
              statusColor(data.status)
            )}
          >
            <span
              className={
                'text-[10px] rounded-3xl uppercase leading-[18px] text-ellipsis overflow-hidden whitespace-nowrap'
              }
            >
              {data.status || '-'}
            </span>
          </div>
        </Skeleton.Loader>
      ),
    },
    {
      title: 'Block Time',
      key: 'created_at',

      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
          classNames={{ content: 'text-white' }}
        />
      ),
    },
    {
      title: 'Extrinsics Root',
      key: 'extrinsic_root',
      render: (data: ColumnData) => (
        <TableColumns
          colIndex="extrinsic_root"
          value={data[data.columnKey]}
          withTruncate
          withCopyButton
          classNames={{ content: 'text-white' }}
        />
      ),
    },
    {
      title: 'Hash',
      key: 'hash',
      render: (data: ColumnData) => (
        <TableColumns
          colIndex="hash"
          value={data[data.columnKey]}
          withTruncate
          withCopyButton
          classNames={{ content: 'text-white' }}
        />
      ),
    },
    {
      title: 'Parent Hash',
      key: 'parent_hash',
      render: (data: ColumnData) => (
        <TableColumns
          value={data[data.columnKey]}
          colIndex="parent_hash"
          withTruncate
          withCopyButton
          classNames={{ base: 'md:justify-start', content: 'text-white' }}
        />
      ),
    },
  ];
};

export default BlockColumn;
