import { Dispatch, useMemo, useState } from 'react';

import { Filter } from 'components';
import { FilterField } from 'components/common/Filter';
import { useApiAccess } from 'contexts/AccessProvider';
import { FilterType, useFilterQuery } from 'gql';

function useFilter(filterType: FilterType, setPageNumber: Dispatch<number>) {
  const { sessionKey } = useApiAccess();

  const { data, loading } = useFilterQuery({
    variables: { sessionKey, filterType },
  });

  const [filterValue, setFilterValue] = useState<Array<string>>([]);
  const [selectedField, setSelectedField] = useState({ column: '', value: '' });

  let filterKeys: Array<string> = useMemo(() => [], []);
  if (data) {
    filterKeys = data.filter.filterKey as Array<string>;
  }

  const handleApply = (obj: { column: string; value: string }) => {
    setSelectedField(obj);
    setPageNumber(1);
  };

  const FilterController = useMemo(() => {
    return (
      <Filter
        filterField={filterKeys as FilterField[]}
        filterValue={filterValue}
        handleApply={handleApply}
        setFilterValue={setFilterValue}
        data={data}
        filterType={filterType}
      />
    );
  }, [filterValue, filterKeys, data, filterType]);

  return { loading, FilterController, selectedField };
}

export default useFilter;
