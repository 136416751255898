import { Icon } from '@analog/ui/src';
import allImgPaths from 'assets/allImgPaths';
import { Text } from 'components';
import { Skeleton } from 'components/Skeleton';
import TableColumns from 'components/Table/TableColumns';
import { FontColors } from 'consts';
import { Link } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { ColumnData } from 'types';
import { getIcon, timeAgo } from 'utils';

const transformToNumber = (td: string) => Number(td).toFixed(2);

const ValidatorColumn = () => {
  return [
    {
      title: 'Address',
      key: 'address',
      render(data: ColumnData) {
        return (
          <Link
            className="flex gap-[10px] col-span-2 md:col-span-1 cursor-pointer items-center"
            to={`${routes.validator}/${data[data.columnKey]}`}
            state={{ data: data }}
          >
            <Icon icon={allImgPaths.addressIcon} />
            <div className={'flex flex-col overflow-hidden w-full'}>
              <TableColumns
                value={data[data.columnKey]}
                withTruncate
                withCopyButton
                textColor={FontColors.PURPLE}
              />
            </div>
          </Link>
        );
      },
    },
    {
      title: 'Discovered',
      key: 'discoveredAt',
      render(data: ColumnData) {
        return (
          <TableColumns
            value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
            classNames={{ content: 'text-white' }}
          />
        );
      },
    },
    {
      title: 'Self Bonded',
      key: 'selfBonded',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
    {
      title: 'Total Bonded',
      key: 'totalBonded',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
    {
      title: 'Last Updated',
      key: 'updated_at',
      render(data: ColumnData) {
        return (
          <TableColumns
            value={data[data.columnKey] ? timeAgo(new Date(data[data.columnKey])) : '-'}
            classNames={{ content: 'text-white' }}
          />
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className=" h-4" containerClassName="flex">
            <TableColumns
              value={data[data.columnKey]}
              beforeImagePath={data[data.columnKey] ? getIcon(data[data.columnKey]) : '-'}
              classNames={{ beforeIcon: 'h-[9px] w-[9px]', content: 'capitalize text-white' }}
            />
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Commission',
      key: 'commission',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
    {
      title: 'Nominator Count',
      key: 'nominatorCount',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
    {
      title: 'Era Rewards',
      key: 'eraRewardPoints',
      render(data: ColumnData) {
        return (
          <Skeleton.Loader className="flex md:justify-end" containerClassName="flex">
            <Text extraClass="text-white text-sm flex">
              {transformToNumber(data[data.columnKey])}
            </Text>
          </Skeleton.Loader>
        );
      },
    },
    {
      title: 'Total Reward',
      key: 'totalRewardPoints',
      render(data: ColumnData) {
        return (
          <Text extraClass="text-white text-sm">{transformToNumber(data[data.columnKey])}</Text>
        );
      },
    },
    {
      title: 'Latest Blocks',
      key: 'latestBlock',
      render(data: ColumnData) {
        return <Text extraClass="text-white text-sm">{data[data.columnKey]}</Text>;
      },
    },
  ];
};
export default ValidatorColumn;
