import { useApiAccess } from 'contexts/AccessProvider';
import { useBlockDetailsQuery } from 'gql';
import { useParams } from 'react-router-dom';

import BlockOverViewDetails from './components/BlockOverViewDetails';

export const useBlockDetails = () => {
  const { sessionKey } = useApiAccess();
  const { idOrHash } = useParams();

  const block = idOrHash?.startsWith('0x')
    ? { blockHash: idOrHash }
    : { blockNumber: +(idOrHash || -1) };

  const {
    data: blockDetailsData,
    loading: isLoading,
    error,
  } = useBlockDetailsQuery({ variables: { sessionKey, ...block } });

  const overviewDetails = BlockOverViewDetails({ blockDetailsData });

  return {
    isLoading,
    blockDetailsData,
    overviewDetails,
    error,
  };
};
