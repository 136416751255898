import { DEFAULT_PAGE_SIZE } from 'consts';
import { useListValidatorsQuery } from 'gql';
import { usePagination, useSortBy } from 'hooks';

import { useApiAccess } from '../../../contexts/AccessProvider';

export function useValidatorList() {
  const { sessionKey } = useApiAccess();
  const { PageController, setTotalDataCount, pageSize, pageNumber } = usePagination({
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  const { sortField, sortOrder, onSort } = useSortBy();

  const { data: validatorsData, loading: isLoading } = useListValidatorsQuery({
    variables: {
      page: { number: pageNumber, size: pageSize },
      ...(sortField && { sort: { field: sortField, order: sortOrder } }),
      sessionKey,
    },
    onCompleted(data) {
      setTotalDataCount(data.listValidators?.count || 0);
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    PageController,
    validatorsData: validatorsData?.listValidators?.data || [],
    isLoading,
    pageSize,
    sortOrder,
    onSort,
  };
}
