import { DetailsList } from '@analog/ui';
import { Heading, ResourceNotFound, SearchBox } from 'components';
import { Skeleton } from 'components/Skeleton';

import useGMPDetails from './useGMPDetails';

const GMPDetails = () => {
  const { isLoading, id, taskData, title, error } = useGMPDetails();

  if (error) {
    return <ResourceNotFound page={'gmp'} searchType={'Tasks'} />;
  }

  return (
    <Skeleton.Provider isLoading={isLoading}>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading title={`Task #${id}`} isLoading={isLoading} />
        <SearchBox defaultSearchType={'Tasks'} />
      </div>
      <div className="md:mt-[40px] mt-[32px]">
        <DetailsList title={title} details={taskData} isLoading={isLoading} />
      </div>
    </Skeleton.Provider>
  );
};

export default GMPDetails;
