import { DetailsList } from '@analog/ui';
import { Heading, ResourceNotFound, SearchBox } from 'components';
import { Skeleton } from 'components/Skeleton';

import BlockRelationalData from './components/BlockRelationalData';
import { useBlockDetails } from './useBlockDetails';

const BlockDetails = () => {
  const { isLoading, blockDetailsData, overviewDetails, error } = useBlockDetails();

  if (error) {
    return <ResourceNotFound page={'blocks'} searchType={'Blocks'} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading title={`Block #${blockDetailsData?.blockDetails?.number}`} isLoading={isLoading} />
        <SearchBox defaultSearchType={'Blocks'} />
      </div>

      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <Skeleton.Provider isLoading={isLoading}>
          <DetailsList title={'Overview'} details={overviewDetails} isLoading={isLoading} />
          <BlockRelationalData />
        </Skeleton.Provider>
      </div>
    </>
  );
};

export default BlockDetails;
